<template>

  <div>
    <p>Your current role is</p>

    <h4>You are in home.</h4>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userRole: '',
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
}
</script>
